<template>
	<div>
		<div style="text-align: center; font-size: 18px; font-weight: bold; padding-top: 20px;">会员制及积分奖励</div>
		<div style="margin: 10px;border: #b9cee1 1px solid;">
			<div style="display: flex;flex-direction: row; background-color: #2ea2eb;color: #fff;text-align: center;padding: 5px 0;">
				<div style="flex: 1;">会员等级</div>
				<div style="flex: 1;">资格要求</div>
				<div style="flex: 1;">折扣优惠价</div>
				<div style="flex: 1;">积分奖励<br>(直接客户)</div>
				<div style="flex: 1;">积分奖励<br>(次接客户)</div>
			</div>
			<div style="display: flex;flex-direction: row;background-color: #cee0f8;text-align: center;padding: 5px 0; ">
				<div style="flex: 1;">钻石</div>
				<div style="flex: 1;">购买酒品达50000元</div>
				<div style="flex: 1;">6折</div>
				<div style="flex: 1;">10%</div>
				<div style="flex: 1;">5%</div>
			</div>
			<div style="display: flex;flex-direction: row;background-color: #e8f0fb;text-align: center; padding: 5px 0; ">
				<div style="flex: 1;">铂金</div>
				<div style="flex: 1;">购买酒品达30000元</div>
				<div style="flex: 1;">7折</div>
				<div style="flex: 1;">6%</div>
				<div style="flex: 1;">3%</div>
			</div>
			<div style="display: flex;flex-direction: row;background-color: #cee0f8;text-align: center;padding: 5px 0;  ">
				<div style="flex: 1;">黄金</div>
				<div style="flex: 1;">购买酒品达10000元</div>
				<div style="flex: 1;">8折</div>
				<div style="flex: 1;">3%</div>
				<div style="flex: 1;">1.5%</div>
			</div>
			<div style="display: flex;flex-direction: row;background-color: #e8f0fb;text-align: center;padding: 5px 0;  ">
				<div style="flex: 1;">白银</div>
				<div style="flex: 1;">购买酒品达1000元</div>
				<div style="flex: 1;">9折</div>
				<div style="flex: 1;">2%</div>
				<div style="flex: 1;">1%</div>
			</div>
			<div style="display: flex;flex-direction: row;background-color: #cee0f8;text-align: center;padding: 5px 0;  ">
				<div style="flex: 1;">青铜</div>
				<div style="flex: 1;">无</div>
				<div style="flex: 1;">9.5折</div>
				<div style="flex: 1;">1%</div>
				<div style="flex: 1;">0.5%</div>
			</div>
		</div>
	</div>
</template>

<script>
import { init } from '@/common/weixinSDK'
export default {
	name: 'index',
		components: {
	},
	data() {
		return {
			title:"三个零会员说明",
			imgUrl: 'https://static.tannatong.com/sanlinghui/common/mp_share.jpg'
		}
	},
	created() {
		init({
			APIs: ['hideOptionMenu', 
			'hideAllNonBaseMenuItem', 
			'showMenuItems', 
			'hideMenuItems', 
			'onMenuShareTimeline', 
			'onMenuShareAppMessage'],
			callback: {
				'title': '三个零会员说明',
				'imgUrl': this.imgUrl,
				'description': '三个零会员制及积分奖励',
				'url': "http://www.sangeling.com/member",
				successMethod: ''
			}
		})

	},
	methods: {

	} 
}
</script>

<style>


</style>
